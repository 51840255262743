import React from 'react'

import { render } from 'react-dom'
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import authReducer from './store/reducers/auth'
import App from './App'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import '@material/react-material-icon/dist/material-icon.css'
import ReactGA from "react-ga";
const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
  ],
}
const rootReducer = combineReducers({
  auth: authReducer,
})

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhances(applyMiddleware(thunk)),
)
const persistor = persistStore(store)

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

//Initialize GA4
ReactGA.initialize("G-CT2LL2DQVZ");

render(app, document.getElementById('root'))
